.Login_bg {
    background-size: cover;
    /* background-image: url(/latestassets1/img/section06.png) !important;*/
    /* background-repeat: no-repeat; */
    /* background-attachment: fixed;
    background: linear-gradient(45deg,#ffe569 0%, #8fd41f  80%) !important; */
    /* height: 110vh; */
    background-color: #0b0b0b;
    /* background-image: url(../../Assets/bg.png); */
    padding: 0 !important;
    margin: 0 !important;
    min-height: 100vh;
    max-height: 100%;
}


.modal {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.modal-content {
    /* background-color: #a8acff; */
    margin: auto;
    padding: 20px 10px;
    /* border: 1px solid fff; */
    /* width: 300px; */
    border-radius: 6px;
}

.boxset {
    color: #fff !important;
}

.btn_Model {
    padding: 10px 20px;
    border-radius: 2.4rem;
    display: inline-block;
    cursor: pointer;
    transition: .2s linear;
    color: #fff !important;
    text-decoration: none;
    font: 400 1rem "Mukta Vaani", sans-serif;
    border-width: 0;
    margin-bottom: 0.5rem;
    margin-top: 10px;
    /* width: 100%; */
    max-width: 13rem;
    box-shadow: 2px 3px 2px rgba(166, 165, 165, .16);
    background: #198754;
}

.login {
    background: linear-gradient(90deg, #a2d254, #ffd300);
    color: black;
    font-weight: 600;
    border: none;
    /* box-shadow: 0px 0px 3px 1px #000; */
    margin-top: 10px;
}

.reg-layout {
    color: #fff !important;
}

.subtitle {
    color: #ffffff;
    /* font-weight: 600; */
}

.note {
    color: white;
    margin-bottom: 0px;
}

.loginagain {
    color: white;
    margin-bottom: 5px;

}

.copyright {
    color: white;
    font-weight: 100;
}

.footer-section2 {
    color: white;
    font-weight: 100;
}

.reg-label {
    /* background-image: url(../../Assets/logo.png); */
    position: absolute;
    top: 0px;
    left: 67%;
    transform: translate(-50%, 25px);
    display: block;
    width: 14.5rem;
    height: 6rem;
    background-repeat: no-repeat;
    background-size: contain;
}

#reg-layout .reg-box {
    position: relative;
    background: #313131;
    border-radius: 20px;
    overflow: hidden;
    -webkit-box-shadow: -2px 7px 23px -5px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -2px 7px 23px -5px rgba(0, 0, 0, 0.75);
    box-shadow: -2px 7px 23px -5px rgba(0, 0, 0, 0.75);
    border: 1px solid #5f5b5b;
    max-width: 30rem;
    width: 100%;
    border-radius: 35px;
    margin: 50px auto;

    box-sizing: border-box;
    padding: 1rem 2rem 1rem;
    text-align: center;
}

::placeholder {
    color: #000 !important;
    font-weight: 600;
}

#reg-layout .form-input {

    padding: 0 1rem;
    box-sizing: border-box;
    border: 0px solid #535578;
    background: #fff;
    width: 100%;
    margin: 10px 0px 10px;
    /* box-shadow: 0px 0px 4px 1px #000; */
    /* transition: border-color .2s linear; */
    /* font: 300 1.6rem "Cairo", Arial, Helvetica, sans-serif; */
    color: #000000;
    /* font-weight: bold; */
    border-radius: 5px;
    height: 31px;

}


.modal-content-register {
    background: #70991d !important;
    box-shadow: 0 0 14px rgba(245, 191, 40, .5);
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 20px 10px;
    pointer-events: auto;
    position: relative;
    width: 20rem;
}



@media screen and (max-width: 800px) {

    .Mobile_MintingHistory_Main .TreeImgMian {
        overflow-x: scroll;
        position: relative;
        left: 0;
        width: 350%;
        padding-left: 250px;
    }
}

@media screen and (max-width:800px) {
    .inFull{
        display: none;
    }
}